import Cards from "./components/Cards";

function App() {
  return (
    <div className="App">
      <h1>Memory game - React </h1>
      <Cards/>
      {/* <div className="container">

      </div> */}
    </div>
  );
}

export default App;
